@use '../assets/fonts/effra';
@use '../assets/fonts/jaf-domus';
@use '../assets/fonts/bryant-pro';
@use '../assets/fonts/source-han-sans-tw';

/*
 * The Typekit service used to deliver this font or fonts for use on websites
 * is provided by Adobe and is subject to these Terms of Use
 * http://www.adobe.com/products/eulas/tou_typekit. For font license
 * information, see the list below.
 *
 * bryant-web:
 *   - http://typekit.com/eulas/00000000000000007735f4f4
 *   - http://typekit.com/eulas/00000000000000007735f4fa
 *   - http://typekit.com/eulas/00000000000000007735f4f3
 *   - http://typekit.com/eulas/00000000000000007735f4fb
 * bryant-web-alternate:
 *   - http://typekit.com/eulas/00000000000000007735f4f2
 *   - http://typekit.com/eulas/00000000000000007735f4fc
 * effra:
 *   - http://typekit.com/eulas/00000000000000003b9b489b
 *   - http://typekit.com/eulas/00000000000000003b9b489a
 *   - http://typekit.com/eulas/00000000000000003b9b489e
 *   - http://typekit.com/eulas/00000000000000003b9b489f
 *   - http://typekit.com/eulas/00000000000000003b9b489c
 *   - http://typekit.com/eulas/00000000000000003b9b48a0
 *
 * © 2009-2023 Adobe Systems Incorporated. All Rights Reserved.
 */
/*{"last_published":"2023-07-31 14:46:52 UTC"}*/

//@import url("https://p.typekit.net/p.css?s=1&k=lqz2dnb&ht=tk&f=7061.7062.23714.23715.7065.7066.9785.9786.9787.9788.9791.9793&a=102917321&app=typekit&e=css");

//@font-face {
//font-family:"bryant-web";
//src:url("https://use.typekit.net/af/2efe84/00000000000000007735f4f4/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/2efe84/00000000000000007735f4f4/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/2efe84/00000000000000007735f4f4/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
//font-display:auto;font-style:normal;font-weight:400;font-stretch:normal;
//}
//
//@font-face {
//font-family:"bryant-web";
//src:url("https://use.typekit.net/af/e220d1/00000000000000007735f4fa/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"),url("https://use.typekit.net/af/e220d1/00000000000000007735f4fa/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"),url("https://use.typekit.net/af/e220d1/00000000000000007735f4fa/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
//font-display:auto;font-style:normal;font-weight:700;font-stretch:normal;
//}

//@font-face {
//font-family:"bryant-web";
//src:url("https://use.typekit.net/af/fe7605/00000000000000007735f4f3/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff2"),url("https://use.typekit.net/af/fe7605/00000000000000007735f4f3/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff"),url("https://use.typekit.net/af/fe7605/00000000000000007735f4f3/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("opentype");
//font-display:auto;font-style:italic;font-weight:400;font-stretch:normal;
//}
//
//@font-face {
//font-family:"bryant-web";
//src:url("https://use.typekit.net/af/e2e010/00000000000000007735f4fb/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff2"),url("https://use.typekit.net/af/e2e010/00000000000000007735f4fb/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff"),url("https://use.typekit.net/af/e2e010/00000000000000007735f4fb/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("opentype");
//font-display:auto;font-style:italic;font-weight:700;font-stretch:normal;
//}

//@font-face {
//font-family:"bryant-web-alternate";
//src:url("https://use.typekit.net/af/6daa29/00000000000000007735f4f2/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/6daa29/00000000000000007735f4f2/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/6daa29/00000000000000007735f4f2/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
//font-display:auto;font-style:normal;font-weight:400;font-stretch:normal;
//}
//
//@font-face {
//font-family:"bryant-web-alternate";
//src:url("https://use.typekit.net/af/5b38b0/00000000000000007735f4fc/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"),url("https://use.typekit.net/af/5b38b0/00000000000000007735f4fc/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"),url("https://use.typekit.net/af/5b38b0/00000000000000007735f4fc/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
//font-display:auto;font-style:normal;font-weight:700;font-stretch:normal;
//}

//@font-face {
//font-family:"effra";
//src:url("https://use.typekit.net/af/2dce9d/00000000000000003b9b489b/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/2dce9d/00000000000000003b9b489b/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/2dce9d/00000000000000003b9b489b/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
//font-display:auto;font-style:normal;font-weight:400;font-stretch:normal;
//}
//
//@font-face {
//font-family:"effra";
//src:url("https://use.typekit.net/af/ecc7e6/00000000000000003b9b489c/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("woff2"),url("https://use.typekit.net/af/ecc7e6/00000000000000003b9b489c/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("woff"),url("https://use.typekit.net/af/ecc7e6/00000000000000003b9b489c/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("opentype");
//font-display:auto;font-style:normal;font-weight:500;font-stretch:normal;
//}
//
//@font-face {
//font-family:"effra";
//src:url("https://use.typekit.net/af/23ddd7/00000000000000003b9b489e/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"),url("https://use.typekit.net/af/23ddd7/00000000000000003b9b489e/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"),url("https://use.typekit.net/af/23ddd7/00000000000000003b9b489e/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
//font-display:auto;font-style:normal;font-weight:700;font-stretch:normal;
//}
//
//@font-face {
//font-family:"effra";
//src:url("https://use.typekit.net/af/e4377d/00000000000000003b9b48a0/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n9&v=3") format("woff2"),url("https://use.typekit.net/af/e4377d/00000000000000003b9b48a0/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n9&v=3") format("woff"),url("https://use.typekit.net/af/e4377d/00000000000000003b9b48a0/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n9&v=3") format("opentype");
//font-display:auto;font-style:normal;font-weight:900;font-stretch:normal;
//}

//@font-face {
//font-family:"effra";
//src:url("https://use.typekit.net/af/199ff2/00000000000000003b9b489a/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff2"),url("https://use.typekit.net/af/199ff2/00000000000000003b9b489a/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff"),url("https://use.typekit.net/af/199ff2/00000000000000003b9b489a/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("opentype");
//font-display:auto;font-style:italic;font-weight:400;font-stretch:normal;
//}
//
//@font-face {
//font-family:"effra";
//src:url("https://use.typekit.net/af/f8c60c/00000000000000003b9b489f/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff2"),url("https://use.typekit.net/af/f8c60c/00000000000000003b9b489f/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff"),url("https://use.typekit.net/af/f8c60c/00000000000000003b9b489f/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("opentype");
//font-display:auto;font-style:italic;font-weight:700;font-stretch:normal;
//}

//.tk-bryant-web { font-family: "bryant-web",sans-serif; }
//.tk-bryant-web-alternate { font-family: "bryant-web-alternate",sans-serif; }
//.tk-effra { font-family: "effra",sans-serif; }
