.popover-custom {
  width: 200px;
  background: #3A3B3B;
  color: #FFFFFF;
  .popover-arrow {
    filter: invert(1);
  }
  .popover-content {
    &.popover-body {
      padding: 0;
      background-color: transparent;
    }
    .divider {
      margin: 0;
      border-color: var(--dl-color-transparency_light-neutral-8);
    }
    .menu-entry {
      border-radius: 0;
      border-bottom: solid 1px !important;
      border-color: black !important;
    }
    .menu-entry:last-child {
      border-bottom: none !important;
    }
    button {
      &.popover-button {
        height: 56px;
        padding: 16px 8px;
        width: 100%;
        text-align: start;
        &:hover {
          .label-text {
            text-decoration: underline;
          }
        }
        &:active, &.active {
          outline-style: none;
        }
      }
      .label-text {
        color: #FFFFFF;
        margin-left: 8px;
      }
      .spinner {
        float: right;
      }
    }
  }
}
