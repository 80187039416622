.signout-modal {
  .modal-content {
    border-radius: 25px;
    .modal-title {
      font-size: 20px;
      font-style: normal;
      font-family: 'Bryant Pro';
      font-weight: 700;
      font-stretch: normal;
    }
    .modal-body {
      font-size: medium;
    }
    .btn-close {
      font-size: x-small;
    }
    .btn-primary {
      padding: 12px 24px;
      border-radius: 25px;
      background-color: var(--dl-color-background-primary-500);
    }
    .btn-default {
      padding: 12px 24px;
      border-style: none;
      color: var(--dl-color-semantics-negative-base);
    }
  }
}

.takeover-modal {
  height: 100%;
  overflow: hidden;
  --bs-modal-width: 100%;
  --bs-modal-padding: 0;
  --bs-modal-margin: 0;
  .modal-content {
    width: 100vw;
    border-radius: 0;
    overflow-x: hidden;
    overflow-y: auto;
    border: none;
  }
  &.vh-100 {
    .modal-content {
      height: 100vh;
    }
  }
}
