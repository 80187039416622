@charset "UTF-8";

@use './material';
@use './bootstrap';

@use './normalize';
@use './typography';
@use './color';
@use './spacing';
@use './forms';
@use './common';
@use './scrollbar';
@use './theme';
@use './spinning';
@use './modals';
@use './popovers';

html, body {
  height: 100%;
}

body {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0;
  padding: 0;
  width: 100%;
  overflow: hidden;
}

html {
  overscroll-behavior: none;
}
