@use 'sass-mediascreen/mediascreen';

.noscrollbar {
  // Works on Firefox
  scrollbar-width: none;
  // Internet Explorer 10+
  -ms-overflow-style: none;
  scrollbar-color: transparent black;
  &.dark {
    scrollbar-color: transparent black;
  }
  &.light {
    scrollbar-color: transparent white;
  }
  // Works on Chrome, Edge, and Safari
  &::-webkit-scrollbar {
    width: 0px;
    display: none;
    -webkit-appearance: none;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: transparent;
    border-radius: 20px;
    border: 0px solid black;
  }
//  @include mediascreen.device(desktop) {
//    box-sizing: content-box;
//    padding-right: 1.5rem !important;
//  }
  .modal-content {
    // Works on Firefox
    scrollbar-width: thin;
    // Internet Explorer 10+
    -ms-overflow-style: none;
    scrollbar-color: transparent black;
    &.dark {
      scrollbar-color: transparent black;
    }

    &.light {
      scrollbar-color: transparent white;
    }
    // Works on Chrome, Edge, and Safari
    &::-webkit-scrollbar {
      width: 0px;
      display: none;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background-color: transparent;
      border-radius: 20px;
      border: 0px solid black;
    }
    @include mediascreen.device(desktop) {
      box-sizing: content-box;
      //padding-left: 1.5rem !important;
      padding-right: 1.5rem !important;
    }
  }
}
