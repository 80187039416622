@use './fonts';

body {
  font-family: 'Bryant Pro', 'jaf-domus', sans-serif, source-han-sans-traditional;
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
}

// headings -------------------------
.headingregularlarge {
  font-size: 1.875rem;
  font-style: normal;
  font-family: 'Bryant Pro';
  font-weight: 400;
  font-stretch: normal;
}
.headingregularsmall {
  font-size: 1.275rem;
  font-style: normal;
  font-family: 'Bryant Pro';
  font-weight: 700;
  font-stretch: normal;
}
.headingboldxlarge {
  font-size: 2.188rem;
  font-style: normal;
  font-family: 'Bryant Pro';
  font-weight: 700;
  font-stretch: normal;
}
.headingboldmedium {
  font-size: 1.53rem;
  font-style: normal;
  font-family: 'Bryant Pro';
  font-weight: 700;
  font-stretch: normal;
}
.headingboldxsmall {
  font-size: 1.063rem;
  font-style: normal;
  font-family: 'Bryant Pro';
  font-weight: 700;
  font-stretch: normal;
}
.headingboldlarge {
  font-size: 1.83625rem;
  font-style: normal;
  font-family: 'Bryant Pro';
  font-weight: 700;
  font-stretch: normal;
}
.headingboldsmall {
  font-size: 1.275rem;
  font-style: normal;
  font-family: 'Bryant Pro';
  font-weight: 700;
  font-stretch: normal;
}
// labels -------------------------
.labelboldlarge {
  font-size: 20.4px;
  font-style: normal;
  font-family: 'effra';
  font-weight: 700;
  font-stretch: normal;
  line-height: 24.24px;
}
.labelmediummedium {
  font-size: 1.063rem;
  font-style: normal;
  font-family: 'effra';
  font-weight: 500;
  font-stretch: normal;
}
.labelregularmedium {
  font-size: 1.063rem;
  font-style: normal;
  font-family: 'effra';
  font-weight: 400;
  font-stretch: normal;
}
.labelregularsmall {
  font-size: 14px;
  font-style: normal;
  font-family: 'effra';
  font-weight: 400;
  font-stretch: normal;
}
.labelboldsmall {
  font-size: 0.88563rem;
  font-style: normal;
  font-family: 'effra';
  font-weight: 700;
  font-stretch: normal;
}
.labelmediumsmall {
  font-size: 0.88563rem;
  font-style: normal;
  font-family: 'effra';
  font-weight: 500;
  font-stretch: normal;
}
.labelboldmedium {
  font-size: 1.063rem;
  font-style: normal;
  font-family: 'effra';
  font-weight: 700;
  font-stretch: normal;
}
.labelboldxsmall {
  font-size: 1.063rem;
  font-style: normal;
  font-family: 'effra';
  font-weight: 700;
  font-stretch: normal;
}
.labelregularxsmall {
  font-size: 12px;
  font-style: normal;
  font-family: 'effra';
  font-weight: 400;
  font-stretch: normal;
}
.labelboldxxlarge {
  font-size: 30px;
  font-style: normal;
  font-family: 'effra';
  font-weight: 700;
  font-stretch: normal;
}
.labelmediumlarge {
  font-size: 20px;
  font-style: normal;
  font-family: 'effra';
  font-weight: 500;
  font-stretch: normal;
}
.labelregularxlarge {
  font-size: 25px;
  font-style: normal;
  font-family: 'effra';
  font-weight: 400;
  font-stretch: normal;
}
// paragraphs -------------------------
.paragraphmedium {
  font-size: 1.063rem;
  font-style: normal;
  font-family: 'effra';
  font-weight: 400;
  font-stretch: normal;
}
.paragraphsmall {
  font-size: 14px;
  font-style: normal;
  font-family: 'effra';
  font-weight: 400;
  font-stretch: normal;
}
// links -------------------------
.linkmediummedium {
  font-size: 1.063rem;
  font-style: normal;
  font-family: 'effra';
  font-weight: 500;
}
//styleName: link/small/medium;
.linksmallmedium {
  font-family: 'effra';
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: -0.01em;
  text-align: left;
  text-decoration: underline;
}
