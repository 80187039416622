@use 'pop-ui';

.smartbanner {
  z-index: 1000;
}

.tour-step {
  background-color: transparent !important;
}

.header-snackbar {
  height: 5.5rem;
  padding: 0;
  width: 100vw;
  --mdc-snackbar-container-color: transparent;
  .mat-mdc-snack-bar-container.mat-mdc-snackbar__surface {
    padding: 0;
    height: 80px;
    width: 100%;
    max-width: none !important;
    box-shadow: none !important;
  }
  .mat-mdc-button.mat-mdc-snack-bar-action {
    color: black !important;
  }
  .mdc-snackbar__surface {
    width: 100%;
    max-width: none !important;
    box-shadow: none !important;
    background-color: transparent !important;
  }
  .mdc-snackbar__label {
    padding: 0 !important;
  }
  .mat-mdc-card-header {
    padding:0.5rem !important;
  }
  .header-card {
    --mdc-outlined-card-container-color: #2E7D32 !important;
    --mat-card-title-text-color: #fff !important;
    --mat-card-subtitle-text-color: #fff !important;
    .header-icon {
      color: var(--mat-card-title-text-color) !important;
      align-self: center !important;
      padding-right: 2rem;
    }
  }
}

.tutorial-snackbar {
  --mdc-snackbar-container-color: transparent;
  .mdc-snackbar__surface {
    width: 100%;
    max-width: none !important;
    box-shadow: none !important;
    background-color: transparent !important;
  }
}
