@use '@angular/material' as mat;

@include mat.core();

@import '@angular/material/prebuilt-themes/indigo-pink.css';
//@import '@angular/material/prebuilt-themes/deeppurple-amber.css';
@import "https://fonts.googleapis.com/icon?family=Material+Icons";
//@import "https://fonts.googleapis.com/icons?family=Material+Icons|Material+Icons+Outlined|Material+Icons+Two+Tone|Material+Icons+Round|Material+Icons+Sharp";
/*
$pop-palette: (
 50: #e8eaf6,
 100: #c5cae9,
 200: #9fa8da,
 300: #7986cb,
 // ... continues to 900
 contrast: (
   50: rgba(black, 0.87),
   100: rgba(black, 0.87),
   200: rgba(black, 0.87),
   300: white,
   // ... continues to 900
 )
);

// Define a theme.
$primary: mat.define-palette(mat.$indigo-palette);
$accent:  mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The "warn" palette is optional and defaults to red if not specified.
$warn: mat.define-palette(mat.$red-palette);

$font-family: 'Bryant Pro, jaf-domus, sans-serif, source-han-sans-traditional';

$light-theme: mat.define-light-theme((
  color: (
    primary: $primary,
    accent: $accent
  ),
  typography: mat.define-typography-config($font-family),
  density: 0,
));

// Include all theme styles for the components.
@include mat.core-color($light-theme);
@include mat.all-component-colors($light-theme);
@include mat.typography-hierarchy($light-theme);
// Define a dark theme
$dark-theme: mat.define-dark-theme((
 color: (
   primary: $accent,
   accent: $primary,
 ),
  typography: mat.define-typography-config($font-family),
  density: 0,
));
*/
/*
// Apply the dark theme only when the user prefers light themes.
@media (prefers-color-scheme: dark) {
 // Use the `-color` mixins to only apply color styles without reapplying the same
 @include mat.core-theme($dark-theme);
 @include mat.all-component-colors($dark-theme);
 @include mat.typography-hierarchy($dark-theme);
}
*/
.elevated {
  @include mat.elevation-transition;
  @include mat.elevation(2);
  @media (any-pointer: fine) and (any-hover: hover) {
    &:not(.nohover):hover {
      @include mat.elevation(8);
    }
  }
}

// Customize the entire app. Change :root to your selector if you want to scope the styles.
:root {
  @include mat.sidenav-overrides((
    container-background-color: white,
    content-background-color: white,
  ));
}
