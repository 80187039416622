.circle-wrapper {
  position: relative;
  width: 100px;
  height: 100px;
  float: left;
  margin: 10px;
  .circle {
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    padding: 2.5px;
    background-clip: content-box;
    &.active {
      animation: spin 2s linear infinite;
    }
  }
}

@keyframes spin {
  100% {
    transform: rotateZ(360deg);
  }
}

// ---------- Begin .rotating-border ----------
@keyframes spin {
  from {transform: translate(-50%, -50%) rotate(0);}
  to   {transform: translate(-50%, -50%) rotate(360deg);}
}
.rotating-border {
  --border-radius: 1rem;
  --border-size: 0.5rem;
  --border-bg: conic-gradient(red, yellow, lime, aqua, blue, magenta, red);
  --padding: 1rem;

  position: relative;
  overflow: hidden;
  font-size: 2rem;
  padding: calc(var(--padding) + var(--border-size));
  border-radius: var(--border-radius);
  display: inline-block;

  &::before {
    content: '';
    display: block;
    background: var(--border-bg);
    width: calc(100% * 1.41421356237);
    padding-bottom: calc(100% * 1.41421356237);
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 100%;
    z-index: -2;
    animation: spin 5s linear infinite;
  }
  &--reverse::before{
    animation-direction: reverse;
  }
  &::after{
    content: '';
    position: absolute;
    inset: var(--border-size);
    background: white;
    z-index: -1;
    border-radius: calc(var(--border-radius) - var(--border-size));
  }
}
// ---------- End .rotating-border ----------

// ---------- Begin customizing .rotating-border ----------
.rotating-border--google {
  --border-radius: 0.5rem;
  --border-size: 0.25rem;
  --border-bg: conic-gradient(
    #ea4335 0.0turn 0.125turn,
    #4285f4 0.125turn 0.25turn,
    #fbbc05 0.25turn 0.375turn,
    #34a853 0.375turn 0.5turn,
    #ea4335 0.5turn 0.625turn,
    #4285f4 0.625turn 0.75turn,
    #fbbc05 0.75turn 0.875turn,
    #34a853 0.875turn 1.0turn
  );

  text-transform: uppercase;
  letter-spacing: 0.1em;
  line-height: 2;
}
.rotating-border--rainbow{
  --border-radius: 1.5rem;
  --border-size: 0.5rem;
  --border-bg: conic-gradient(red, yellow, lime, aqua, blue, magenta, red);
}
.rotating-border--black-white{
  --border-radius: 100%;
  --border-size: 0.6rem;
  --border-bg: conic-gradient(
    #fff 0.00turn 0.05turn,
    #333 0.05turn 0.10turn,
    #fff 0.10turn 0.15turn,
    #333 0.15turn 0.20turn,
    #fff 0.20turn 0.25turn,
    #333 0.25turn 0.30turn,
    #fff 0.30turn 0.35turn,
    #333 0.35turn 0.40turn,
    #fff 0.40turn 0.45turn,
    #333 0.45turn 0.50turn,
    #fff 0.50turn 0.55turn,
    #333 0.55turn 0.60turn,
    #fff 0.60turn 0.65turn,
    #333 0.65turn 0.70turn,
    #fff 0.70turn 0.75turn,
    #333 0.75turn 0.80turn,
    #fff 0.80turn 0.85turn,
    #333 0.85turn 0.90turn,
    #fff 0.90turn 0.95turn,
    #333 0.95turn 1.00turn
  );
}
.rotating-border--black-yellow{
  --border-radius: 100%;
  --border-bg: conic-gradient(
    #F0C400 0.1666turn,
    #444 0.1666turn 0.3333turn,
    #F0C400 0.3333turn 0.5turn,
    #444 0.5turn 0.6666turn,
    #F0C400 0.6666turn 0.8333turn,
    #444 0.8333turn
  );
  width: 2.5rem;
  height: 2.5rem;
  text-align: center;
}
// ---------- End customizing .rotating-border ----------
