$source-han-san-tw-path: '/assets/fonts/source-han-sans-tw/';

@font-face {
  font-family: 'source-han-sans-traditional';
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  src: url($source-han-san-tw-path + 'SourceHanSansTW-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'source-han-sans-traditional';
  font-style: normaL;
  font-weight: bold;
  font-display: swap;
  src: url($source-han-san-tw-path + 'SourceHanSansTW-Bold.woff2') format('woff2');
}

@font-face {
  font-family: 'source-han-sans-traditional';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url($source-han-san-tw-path + 'SourceHanSansTW-Medium.woff2') format('woff2');
}

@font-face {
  font-family: 'source-han-sans-traditional';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url($source-han-san-tw-path + 'SourceHanSansTW-Light.woff2') format('woff2');
}
