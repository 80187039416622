body, :host {
  --dl-color-gray-500: #595959;
  --dl-color-gray-700: #999999;
  --dl-color-gray-900: #D9D9D9;
  --dl-size-size-large: 144px;
  --dl-size-size-small: 48px;
  --dl-color-danger-300: #A22020;
  --dl-color-danger-500: #BF2626;
  --dl-color-danger-700: #E14747;
  --dl-color-gray-black: #000000;
  --dl-color-gray-white: #FFFFFF;
  --dl-size-size-medium: 96px;
  --dl-size-size-xlarge: 192px;
  --dl-size-size-xsmall: 16px;
  --dl-space-space-unit: 16px;
  --dl-color-neutral-100: #f5f5f5;
  --dl-color-neutral-500: #8A8C8C;
  --dl-color-primary-100: #003EB3;
  --dl-color-primary-300: #0074F0;
  --dl-color-primary-500: #14A9FF;
  --dl-color-primary-700: #85DCFF;
  --dl-color-success-300: #199033;
  --dl-color-success-500: #32A94C;
  --dl-color-success-700: #4CC366;
  --dl-size-size-xxlarge: 288px;
  --dl-color-tag-cyantext: #00539a;
  --dl-size-size-maxwidth: 1400px;
  --dl-color-color15-black: #000000;
  --dl-radius-radius-round: 50%;
  --dl-color-archived-white: #ffffff;
  --dl-space-space-halfunit: 8px;
  --dl-space-space-sixunits: 96px;
  --dl-space-space-twounits: 32px;
  --dl-color-transparency-64: #000000a3;
  --dl-radius-radius-radius2: 2px;
  --dl-radius-radius-radius4: 4px;
  --dl-radius-radius-radius8: 8px;
  --dl-space-space-fiveunits: 80px;
  --dl-space-space-fourunits: 64px;
  --dl-space-space-threeunits: 48px;
  --dl-color-tag-cyanbackground: #bae6ff;
  --dl-color-popquiz-primary-100: #d4c5fd;
  --dl-color-background-secondary: #ffffff;
  --dl-color-transparency-black-4: #0000000a;
  --dl-color-transparency-black-8: #00000014;
  --dl-color-transparency-black-16: #00000029;
  --dl-color-transparency-black-24: #0000003d;
  --dl-color-transparency-black-40: #00000066;
  --dl-color-transparency-black-56: #0000008f;
  --dl-color-transparency-black-80: #000000cc;
  --dl-color-transparency-white-4: #ffffff0a;
  --dl-color-transparency-white-8: #ffffff14;
  --dl-color-transparency-white-16: #ffffff29;
  --dl-color-transparency-white-24: #ffffff3d;
  --dl-color-transparency-white-40: #ffffff66;
  --dl-color-transparency-white-56: #ffffff8f;
  --dl-color-transparency-white-80: #ffffffcc;
  --dl-space-space-oneandhalfunits: 24px;
  --dl-color-popteams-secondary-500: #5454b8;
  --dl-color-archived-text-secondary: #ffffff;
  --dl-color-archived-text-placeholder: #00000040;
  --dl-color-transparent-transparent: #ffffff;

  --dl-color-background-primary: #381A99;
  --dl-color-background-primary-100: #EBF0FF;
  --dl-color-background-primary-500: #5e2bff;
  --dl-color-background-primary-700: #381499;
  --dl-color-popquiz-primary-500: #6438DD;
  --dl-color-datepicker-active: #5E2BFF;
  --dl-color-datepicker-inactive: #ffffff0a;
  --dl-color-datepicker-inactive-light: #a3a3a3ff;
  --dl-color-selection-active: #5E2BFF;
  --dl-color-selection-disabled: #00000061;
  --dl-color-selection-hover: #5E2BFF8F;
  --dl-color-slider-bar-primary: #0000008f;
  --dl-color-slider-bar-secondary: #ffffff8f;
  --dl-color-base-white: #E3E3E3;

  --dl-color-base-black: #000000;
  --dl-color-semantics-negative-base: #CC334D;
  --dl-color-natural-light-200: #DDE0E0;
  --dl-color-natural-light-700: #616262;
  --dl-color-natural-light-800: #4D4E4E;
  --dl-color-natural-light-1000: #282929;
  --dl-color-natural-light-1100: #181818;
  --dl-color-transparency-light-neutral-8: #FFFFFF14;
  --dl-color-transparency-light-neutral-16: #FFFFFF29;
  --dl-color-transparency-light-neutral-24: #FFFFFF3d;
  --dl-color-transparency-light-neutral-40: #FFFFFF66;
  --dl-color-transparency-light-neutral-56: #FFFFFF8f;
  --dl-color-transparency-dark-neutral: #0000000A;
  --dl-color-transparency-dark-neutral-6: #0000000F;
  --dl-color-transparency-dark-neutral-8: #00000014;
  --dl-color-transparency-dark-neutral-16: #00000029;
  --dl-color-transparency-dark-neutral-24: #0000003d;
  --dl-color-transparency-dark-neutral-38: #00000061;
  --dl-color-transparency-dark-neutral-40: #00000066;
  --dl-color-transparency-dark-neutral-56: #0000008f;
  --dl-color-transparency-dark-neutral-60: #00000099;
  --dl-color-transparency-dark-neutral-87: #000000de;

  --dl-color-yellow-300: #E2C537;
  --dl-color-green-300: #44B34B;

  --dl-color-neutral-light-0: #FEFEFE;
  --dl-color-neutral-light-100: #F2F6F6;
  --dl-color-neutral-light-400: #A9ACAC;
  --dl-color-brand-primary-500: #5E2BFF;
  --dl-color-brand-primary-700: #3A56EC;
}
