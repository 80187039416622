@use 'sass:list';

// spacers -------------------------------------------------------

$heights: (
  1,
  4,
  8,
  16,
  24,
  32,
  40,
  48,
  60,
  100,
);

@each $height in $heights {
  // @debug "spacer height: #{$height}#{"px"}";
  .frame#{$height}pxspacer {
    height: #{$height}#{'px'};
    display: flex;
    position: relative;
    align-items: flex-start;
    align-self: stretch;
    flex-shrink: 0;
    margin-bottom: 0px;
    border-color: transparent;
  }
}
