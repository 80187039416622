$effra-path: '/assets/fonts/effra/';

@font-face {
  font-family: 'effra';
  font-weight: 400;
  font-display: swap;
  src: url($effra-path + 'effra-400.woff2') format('woff2'),
    url($effra-path + 'effra-400.otf') format('opentype');

}
@font-face {
  font-family: 'effra';
  font-weight: 500;
  font-display: swap;
  src: url($effra-path + 'effra-500.woff2') format('woff2'),
    url($effra-path + 'effra-500.otf') format('opentype');
}
@font-face {
  font-family: 'effra';
  font-weight: 700;
  font-display: swap;
  src: url($effra-path + 'effra-700.woff2') format('woff2'),
    url($effra-path + 'effra-700.otf') format('opentype');
}
@font-face {
  font-family: 'effra';
  font-weight: 900;
  font-display: swap;
  src: url($effra-path + 'effra-900.woff2') format('woff2'),
    url($effra-path  + 'effra-900.otf') format('opentype');
}

.tk-effra { font-family: "effra",sans-serif; }
