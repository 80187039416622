$bryant-pro-path: '/assets/fonts/bryant-pro/';

@font-face {
  font-family: 'Bryant Pro';
  font-weight: bold;
  font-display: swap;
  src: url($bryant-pro-path + 'BryantPro-Bold.woff2') format('woff2'),
    url($bryant-pro-path + 'BryantPro-Bold.otf') format('opentype');
}

@font-face {
  font-family: 'Bryant Pro';
  font-weight: light;
  font-display: swap;
  src: url($bryant-pro-path + 'BryantPro-Light.woff2') format('woff2'),
    url($bryant-pro-path + 'BryantPro-Light.otf') format('opentype');
}

@font-face {
  font-family: 'Bryant Pro';
  font-weight: medium;
  font-display: swap;
  src: url($bryant-pro-path + 'BryantPro-Medium.woff2') format('woff2'),
    url($bryant-pro-path + 'BryantPro-Medium.otf') format('opentype');
}

@font-face {
  font-family: 'Bryant Pro';
  font-weight: normal;
  font-display: swap;
  src: url($bryant-pro-path + 'BryantPro-Regular.woff2') format('woff2'),
    url($bryant-pro-path + 'BryantPro-Regular.otf') format('opentype');
}

.tk-bryant-pro { font-family: "Bryant Pro",sans-serif; }

// Alternate

@font-face {
  font-family: 'Bryant Pro Alternate';
  font-weight: bold;
  font-display: swap;
  src: url($bryant-pro-path + 'BryantPro-BoldAlternate.woff2') format('woff2'),
    url($bryant-pro-path + 'BryantPro-BoldAlternate.otf') format('opentype');
}

@font-face {
  font-family: 'Bryant Pro Alternate';
  font-weight: light;
  font-display: swap;
  src: url($bryant-pro-path + 'BryantPro-LightAlternate.woff2') format('woff2'),
    url($bryant-pro-path + 'BryantPro-LightAlternate.otf') format('opentype');
}

@font-face {
  font-family: 'Bryant Pro Alternate';
  font-weight: medium;
  font-display: swap;
  src: url($bryant-pro-path + 'BryantPro-MediumAlternate.woff2') format('woff2'),
    url($bryant-pro-path + 'BryantPro-MediumAlternate.otf') format('opentype');
}

@font-face {
  font-family: 'Bryant Pro Alternate';
  font-weight: normal;
  font-display: swap;
  src: url($bryant-pro-path + 'BryantPro-RegularAlternate.woff2') format('woff2'),
    url($bryant-pro-path + 'BryantPro-RegularAlternate.otf') format('opentype');
}

.tk-bryant-pro-alternate { font-family: "Bryant Pro Alternate",sans-serif; }
